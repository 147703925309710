import React from 'react'
import LibraryWrapper from './LibraryWrapper'
import { ReportGlance } from './../../components/library'

function section() {
    return (
        <LibraryWrapper>
            <ReportGlance 
                title="FEV1"
                help="This is a tooltip text"
                icon="spirometer"
                number="90%"
                description="Good"
                level={5}
                note="Taken today, 9:07 AM" 
                to="/components"
                icon_color="pink"
            />
            <hr />
            <ReportGlance 
                icon="lungs"
                number="0"
                description="Symptoms"
                level={0}
                note="No Data" 
                to="/components"
                icon_color="purple"
            />
            <hr />
            <ReportGlance 
                title="FEV1"
                help="This is a tooltip text"
                icon="spirometer"
                number="-"
                level={0}
                icon_color="pink"
                note="No Data" 
                to="/components"
            />
            <hr />
            <ReportGlance 
                title="C-ACT"
                help="This is a tooltip text"
                icon="clipboard"
                icon_color="blue"
                description="- OK"
                number="20"
                level={2}
                note="Taken today, 6:13 PM" 
                to="/components"
            />
        </LibraryWrapper>
    )
}
export default section
